<template>
	<div :class="themeClass" class="coupCont" >
		<div class="bgImg">
			<!-- <img :src="bgTopImg" alt="" /> -->
			<img src="../assets/bgimg/u651.png" alt="" />
		</div>
		<div class="couponCont width-fill margin-t-10 flex-column">
			<div class="flex-row-between-between-wrap margin-t-15">
				<span class="font-size18 font-weight700">领券中心</span>
        <div style="width: 130px;height:30px;display:flex;position: relative;left: 0px;">
        <div style="display:inline-block;width:23px;height: 23px;background: red;border-radius:50%;color:#fff;font-size:11px;line-height:23px;text-align: center;position: absolute;left: -14px;">{{fCouponCount> 99 ? 99 : fCouponCount}}</div>
				<div  class="comBtn bg-theme font-color-fff bg-theme text-center radius2 margin-l-auto" ref="lableBtnRef" @click="myCoupBtn">
          我的优惠券</div></div>
			</div>
			<div class="accountCont width-fill margin-t-10 flex-row-wrap">
				<div class="coupList" v-for="(item,index) of couponList" :key="index">
					<div class="collectCoupons-card flex-row coupon-con">
						<div class="collectCoupons-card-left">
							<div class="collectCoupons-card1 flex-row-center-center">
								<p class="collectCoupons-card2">¥</p>
								<p :style="item.fDeductionMoney.toString().length <= 4 ? 'font-size:24px' : (item.fDeductionMoney.toString().length >= 5 && item.fDeductionMoney.toString().length <= 6 ? 'font-size: 18px' : 'font-size: 15px')" class="collectCoupons-card3">{{item.fDeductionMoney}}</p>
							</div>
							<div class="collectCoupons-card4 text-center">
								购物优惠
							</div>
						</div>
						<div class="collectCoupons-card-right" style="overflow: hidden;">
							<div class="collectCoupons-card5">
								<div class="collectCoupons-card6 margin-b-5 flex-row right-top-name">{{item.fCouponName}}</div>
								<p class="collectCoupons-card7 margin-b-10">有效期：{{getDate(item.fBeginTime)}}至{{getDate(item.fEndTime)}}</p>
								
								<div>
									满{{item.fMinSaleMoney}}减{{item.fDeductionMoney}}
									<p v-if="item.fStatus == 0" class="collectCoupons-card8 border-FF0000 font-size11 radius10 text-center font-color-FF0000 margin-b-10" @click="useClick(item.fCouponID)">立即使用</p>
									<p  v-if="item.fStatus == -1 && item.fSurplusSum != 0" class="collectCoupons-card8 border-FF0000 font-size11 radius10 text-center font-color-fff bg-FF0000 margin-b-10" @click="receiveClick($event,item.fCouponID)">领取</p>
									<p  v-if="item.fStatus == 1" class="collectCoupons-card8 border-FF0000 font-size11 radius10 text-center font-color-fff bg-FF0000 margin-b-10" style="color: #999;border: #999 1px solid;background-color: #fff;" >已使用</p>
									<p  v-if="item.fStatus == -1 && item.fSurplusSum == 0" class="collectCoupons-card8  font-size11  text-center font-color-fff  margin-b-10" style="color: #999;background-color: #fff;" >已抢光</p>
								</div>
							</div>
							<div v-if="item.useRules.length>0" class="collectCoupons-card9" style="position: relative; left: 10px;height: 11px;" >
									<el-dropdown style="width: 100%;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
										<p style="display: inline-block;">使用规则</p><i class="el-icon-arrow-down el-icon--right" style="display: inline-block; float: right;"></i>
									  </span>
									  <el-dropdown-menu slot="dropdown" style="width: 349px;">
										<el-dropdown-item v-for="(items,indexs) of item.useRules" :key="indexs">{{indexs+1}}.{{items.fUseDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
							</div>
							<span class="iconfont icon-yilingqu stateUse font-color-C9C9C9" v-if="item.fStatus==0 || item.fStatus==1"></span>
							<span class="iconfont icon-yiqiangguang stateUse font-color-C9C9C9" v-if="item.fSurplusSum == 0 && item.fStatus==-1" style="transform:rotate(26deg) scale(1.3);right: -7px;top: -7px;"></span>
						</div>
					</div>
				<!-- 	<div v-show="item.tipsShow" class="coupon-con  margin-t-2 padding-8 font-size12 font-color-666">
						<div v-for="(itemChild,index) of item.useRules" :key="'itemChild' + index" class="flex-row">
							<span class="margin-r-6  font-size14 font-weight700 font-color-999">·</span>
							<span style="line-height: 18px;">{{itemChild}}</span>
						</div>
					</div> -->
				</div>
			</div>			
    <!-- 小球动画 -->
    <div
			   id="animation-el"
			   v-show="animationElStatus"
			   :style="animationElStatus"
			 >1
    </div>
			 <!-- 小球动画 -->
			
		</div>
		
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
	  data() {
	    return {
			/* bgTopImg:require("../assets/u64.vue"), */
		couponList: [],
    fCouponCount: 0,
    animationElStatus: '',//动画
		endPoint: '',//动画截止点
	    };
	  },
	  computed: {
	    ...mapGetters(["getThemeName"]),
	    themeClass() {
	      return `theme-${this.getThemeName}`;
	    },
	  },
	  watch:{
		couponList(newval,oldval){
			this.couponList = newval
		}  
	  },
	  created(){
		  this.getList()
      this.getCustomerMoreInfo()
	  },
	  methods:{
		  
			// 获取所有优惠券
			getList() {
				this.ApiRequestPost('/api/mall/ebactivity/customerreceiverecord/get-homepage-coupon-info', {			
				}).then(res => {				
						this.couponList = res.obj
            this.couponList.forEach(item=>{
						item.tipsShow=false				
					})
            // for (var i = 0; i < elements.length; i++) {
            //     // 对每个元素执行操作
            //     var element = elements[i];
            //     // 例如，可以更改元素的样式
            //     element.style.display = 'none';
            // }
						console.log('领券中心列表',this.couponList)

				}).catch(err => {
					
				})
			},
      getCustomerMoreInfo() {
			  this.ApiRequestPost('/api/mall/ebcustomer/baseinfo/get-customer-more-info')
			    .then(
			      res => {
			        this.fCouponCount = res.obj.fCouponCount
			      },
			      error => {}
			    );
			},
      //添加过度动画
      handleAdd(clientX,clientY) {
          console.log(clientX,clientY);
          const x = clientX - 20;
          const y = clientY - 20;
          console.log(x,y)
          this.createBall(x,y)
      },
      createBall(left,top) {
        let lableBtn = this.$refs.lableBtnRef
        console.log(lableBtn.getBoundingClientRect())
        let rect = lableBtn.getBoundingClientRect() //获取统计按钮位置
        let size = [lableBtn.clientWidth, lableBtn.clientHeight] //统计按钮本身高、宽
        const afterX = rect.x + size[0] / 2 //计算小球最后的位置x
        const afterY = rect.y - (size[1] / 2)//计算小球最后的位置Y
        const x = left
        const y = top
				this.animationElStatus = `color:#fff;position:fixed;top: ${y}px; left: ${x}px;width:15px;height: 15px;line-height:15px;text-align:center;zIndex: 9999;background:red;border-radius: 50%`
			    setTimeout(() => {
			       this.animationElStatus = `color:#fff;position:fixed;top: ${afterY}px; left: ${afterX}px; width:15px;height: 15px;line-height:15px;text-align:center;zIndex: 9999;background:red;transition:all 0.7s cubic-bezier(0.5, -0.3, 1, 1);border-radius: 50%`
			     }, 20);
				 console.log(this.endPoint)
			    setTimeout(() => {
					this.fCouponCount += 1
					this.animationElStatus = ''
				},700)
      },
			//领券点击事件
			receiveClick(event,id) {
				this.collectCouponsShow = true;
				this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/create', {
					"fCouponID": id
				}).then(res => {
					console.log(res);
          this.handleAdd(event.clientX,event.clientY)
					this.getList()
					this.$forceUpdate()
				}).catch(err => {
					if(err.error.code == "EBActivity1025"){
						for(var i = 0;i<this.couponList.length;i++){
							if(this.couponList[i].fCouponID == id){
								this.couponList[i].fSurplusSum = 0
							}
						}
					}
					if(err.error.code == "EBActivity1014"){
						for(var i = 0;i<this.couponList.length;i++){
							if(this.couponList[i].fCouponID == id){
								this.couponList[i].fStatus = 0
							}
						}
					}
					this.getList()
					this.$forceUpdate()
				})
			},
			// 立即使用点击事件
			useClick(id){
				//跳转商品列表
				console.log(id);
        this.$router.push({
					name: 'LimitedActivities'
				});
				// this.$router.push({
				// 	path: '/Goods',
				// 	query: {},
				// });
			},
			// 使用规则
		  slideBtn(item){
			  item.tipsShow = !item.tipsShow;
			  this.$forceUpdate()
		  },
		  myCoupBtn(){
			  this.$router.push({
			  	path: '/PersonalContent/Coupon',
			  	query: {},
			  });
		  }
	  }
	};
</script>

<style scoped lang ="scss">
	
	.color-theme {
	  @include themify($themes) {
	    color: themed("themes_color");
	  }
	}
	.ora-color-theme{
		@include themify($themes) {
			color: #f37b1d;
		}
	}
	.bg-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}
	.coupCont{
		width: 100%;
	}
	/* 公用btn */
	.comBtn{
		width: 110px;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}
	
	.couponCont{
		/* height: 100%; */
		box-sizing: border-box;
		max-width: 1100px;
		margin: auto;
	}
	.accountCont{
	}
	.coupList{
		width:calc(34% - 25px);
		width: -webkit-calc(34% - 25px);
		margin-right: 24px;
		margin-bottom: 24px;
		min-height: 123px;
		/* float:left; */
		word-break:break-all;
		
	}
	.coupList:nth-child(3n+3){
		margin-right: 0px;
	}
	.coupon-con {
		background: #ffffff;
		border-radius: 10px;
		box-shadow: 0px 0px 10px 0px rgba(13, 13, 13, 0.176470588235294);
	}
	.collectCoupons-card1 {
		width: 100%;
		height: 100px;
		font-color: #ffffff;
		display: flex;
		flex-direction: row;
		border-top-left-radius: 10px;
		background-image: linear-gradient(to bottom right, #FA8A77, #FC3720);
	}
	
	.collectCoupons-card-none {
		width: 85px;
		font-color: #ffffff;
		display: flex;
		flex-direction: row;
		border-top-left-radius: 10px;
		background-image: linear-gradient(to bottom right, #E0E0E1, #A7ACBB);
	}
	
	.collectCoupons-card2 {
		font-size: 20px;
		font-size: 20px;
		color: #ffffff;
	}
	
	.collectCoupons-card-left {
		width: 25%;
	}
	
	.collectCoupons-card3 {
		font-weight: 700;
		font-size: 30px;
		color: #ffffff;
	}
	
	.collectCoupons-card4 {
		width: 100%;
		height: 29px;
		border-radius-left-bottom: 10px;
		font-size: 12px;
		font-weight: 400;
		color: #cc3300;
		line-height: 29px;
		background-image: linear-gradient(to right, #FFCBB8, #FF9884);
		border-bottom-left-radius: 10px;
	
	}
	
	.collectCoupons-card4-none1 {
		width: 85px;
		height: 29px;
		/* border-radius: 10px; */
		border-radius-left-bottom: 10px;
		font-size: 12px;
		color: #999999;
		background-color: #F2F2F2;
		line-height: 29px;
		/* p-align: center; */
		border-bottom-left-radius: 10px;
	}
	
	.collectCoupons-card6 {
		color: #000000;
		font-weight: 700;
		font-size: 14px;
	}
	
	.collectCoupons-card6-none2 {
		font-weight: 700;
		font-style: normal;
		color: #999999;
		font-size: 14px;
	}
	.stateUse {
		position: absolute;
		top: 3px;
		right: 1px;
		font-size: 29px;
		z-index: 0;
	}
	.collectCoupons-card7 {
		font-size: 12px;
		color: #999999;
	}
	
	.collectCoupons-card8 {
		width: 63px;
		height: 18px;
		line-height: 18px;
		float: right;
		cursor: pointer;
	}
	
	.collectCoupons-card9 {
		display: flex;
		justify-content: space-between;
		border-top: 1px dashed #cccccc;
		padding-top: 5px;
	}
	
	.collectCoupons-card9 p {
		font-size: 12px;
		color: #999999;
	}
	
	.collectCoupons-card5 {
		height: 100px;
		padding-top: 13px;
		box-sizing: border-box;
	}
	
	.left-bottom {
		height: 58rpx;
		color: #000000;
		line-height: 58rpx;
		border-bottom-left-radius: 20rpx;
		background-color: #F2F2F2;
		color: #999999;
		;
	}
	
	.collectCoupons-card-right {
		width: 75%;
		padding: 0 10px;
		padding-left: 13px;
		position: relative;
		box-sizing: border-box;
	}
	.stateUse{
		position: absolute;
		top: 1px;
		right: 1px;
		font-size: 48px;
	}
	.bgImg{
		width: 100%;
		img{
			width: 100%;
		}
	}
	.icon-arrow-down{
		font-size: 21px;
	}
</style>

				